import React from "react"

import "../styles/footer.sass"

const Footer = () => {
  return (
    <>
    <footer>
        <div className="footer-stroke slim"></div>
        <div className="footer-white">
        BI-Esport  |  Gl. Agdrupvej 17-23  |  9700 Brønderslev | CVR: 40865020
        </div>
        <div className="footer-stroke"></div>
        <div className="footer-blue">
          Copyright © {new Date().getFullYear()}
        </div>
    </footer>
    </>
  )
}

export default Footer