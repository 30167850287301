import React from "react"
import PropTypes from "prop-types"

import Footer from "./footer"
import Headroom from "./headroom"
import SideNav from "./sidenav"

import "../styles/styles.sass"

const Layout = ({ children }) => {

  return (
    <>
      <Headroom />
      <SideNav />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
