import React from "react"
import { Link } from "gatsby"
import { slide as Menu } from "react-burger-menu"

import "../styles/navigation.sass"

export default props => {
  return (
    <Menu width={ '100%' } noOverlay right>
      <ul>
        <li>
          <Link to="/" className="one" activeClassName="active">
            Forside
          </Link>
        </li>
        <li>
          <Link to="/spil" className="two" activeClassName="active">
            Spil
          </Link>
        </li>
        <li>
          <Link to="/tilmelding" className="three" activeClassName="active">
            Tilmelding
          </Link>
        </li>
        <li>
          <Link to="/lan" className="four" activeClassName="active">
            LAN
          </Link>
        </li>
        <li>
          <Link to="/sponsor" className="six" activeClassName="active">
            Sponsor
          </Link>
        </li>
        <li>
          <Link to="/kontakt" className="five" activeClassName="active">
            Kontakt
          </Link>
        </li>
      </ul>
    </Menu>
  );
};